import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-pass-reset',
  templateUrl: './pass-reset.component.html',
  styleUrls: ['./pass-reset.component.scss', '../../../../vendor/styles/pages/authentication.scss']
})
export class PassResetComponent implements OnInit {
  nothing: Object;

  constructor(private apiservice: ApiService,private router: ActivatedRoute,private route: Router) { }
  credentials = {
    email: ''};
    alerts: Array<IAlert> = [];
    id: number;
    type: string;
    message: string;
  ngOnInit() {
  }
onsubmit(){
  this.apiservice.add('rpassword', this.credentials)
      .subscribe(() => {
        this.alerts.push({
          id: 1,
          type: 'info',
          message: ' لقد تم ارسال رسالة الى البريد الخاص بكم تحتوي رابط تغيير كلمة المرور'
        }),
          setTimeout(() => { this.alerts = []; }, 8000);
      this.credentials ={
               email: '',
             }
      }
        , error => {
          console.log(error)
          this.alerts.push({
            id: 1,
            type: 'danger',
            message: 'عنوان البريد غير موجود لدينا'
          }),
            setTimeout(() => { this.alerts = []; }, 3000);
        }

      )
}
public closeAlert(alert: IAlert) {
  const index: number = this.alerts.indexOf(alert);
  this.alerts.splice(index, 1);
}
}
export interface IAlert {
id: number;
type: string;
message: string;
}

