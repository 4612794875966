import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewEncapsulation, Testability, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { WizardComponent } from 'ng2-archwizard';
import { ShareButtons } from '@ngx-share/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';




@Component({
  selector: 'app-mytest',
  templateUrl: './mytest.component.html',
  styleUrls: ['./mytest.component.scss', '../../../vendor/libs/ng2-archwizard/ng2-archwizard.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MytestComponent implements OnInit, AfterViewInit {

  @ViewChild(WizardComponent) public wizard: WizardComponent;

  @Input() event: any;
  @Output() eventClicked = new EventEmitter<Event>();
  theDate: Date;
  uid: any
  qz: any;
  sub = []
  test = {
    bcode: '',
    qzorderno: '',

  };
  buttonName = "facebook"
  data: any
  start: number = 0;
  width: number = 0;
  ntcode: any;
  userid: string;
  ftest = {
    userid: '',
    testid: '',
    bcode: '',
    scoure: 0
  };
  testeval = {
    evalr: '',
    evalw: '',
    evall: '',
    evalt: '',
  };
  qstotal: number
  ntcode1: Object;
  seloption: any = []
  curTab = 'test'
  correctans = 0
  rongans = 0
  ans = []
  subans = []
  inpoption: any
  sortop: any
  scoure: number
  opdata: any;
  ansval = [];
  evaldata: any;
  evalr: number;
  evalw: number;
  evall: number;
  evalt: number;
  subsel: any;
  testevalr: any;
  testevalw: any;
  testevall: any;
  testevalt: any;
  done: Object;
  evaldataar = [];
  viewdiv = 0;
  viewres = 0
  url: any;
  link: any;

  rans = []
  username: any;
  myRawToken: string;
  testname: any;
  qsp = 0
  config =
    { leftTime: 10, notify: [2, 5] }
  testtime: number;
  seconds: number;
  timer;
  qstime: number
  qstimedata: any;

  qstimedataall: any;
  counter: number;
  cont: string;
  interval: NodeJS.Timer;
  view: number;
  modalOption: NgbModalOptions = {}
  @ViewChild("editModal2") private test2: TemplateRef<any>;
  @ViewChild("editModal3") private test3: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private apiservice: ApiService,
    private _changeDetectionRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public share: ShareButtons,
    private router: Router,
    private helper: JwtHelperService
  ) {
    if (localStorage.getItem('access_token')) {
      this.myRawToken = localStorage.getItem('access_token')
      const decodedToken = helper.decodeToken(this.myRawToken);
      this.username = decodedToken.fname + " " + decodedToken.lname
      // console.log(this.username)

    }



  }

  open(content, options = {}) {

    this.modalService.open(content, options)
  }

  ngOnInit() {
    //this.sub = this.route.params.subscribe(params => {
    //   this.id = params['id']})
    if (this.event !== undefined) {
      this.data = this.event;
      // console.log(this.event, "this")
      this.testname = this.data.des
      this.qstotal = this.data.qs.length
      if(this.data.qztype == 2 || this.data.qztype == 3 ){
        this.qstimedataall = this.data.qs
        this.qstimedata = this.qstimedataall.filter(p => p.qsnum == 1)
        this.qstime = this.qstimedata[0].qstime
        // console.log(this.qstime)
        this.timerfn(0)
        this.gettotal()
        this.config = { leftTime: this.testtime, notify: [2] }
        this.modalOption.backdrop = 'static';
        this.modalOption.keyboard = false;
      }
      
      this.width = 100 / (this.data.qs.length)
    } else {
      this.viewdiv = 1
    }
  }

  progadd(no, type, op, val) {
    this.qsp = 0
    // console.log("1")
    //  console.log(type)
    this.width = this.width + (100 / (this.data.qs.length))
    if (type == 1) {
      //  console.log(this.seloption)
      if (this.seloption == '' || this.seloption.is_right == 0) {
        this.seloption.is_right = 0
        this.ans.push({ qsno: no, sel: this.seloption.is_right, r: 0, w: 0, l: 0, t: 0 })
        //   console.log(this.ans, "3")

      } else {
        this.ans.push({ qsno: no, sel: 1, r: val.reading, w: val.writing, l: val.listening, t: val.talking })
        this.seloption.is_right == 0
        //  console.log(this.ans, "1")
      }
      this.seloption = []
    }
    if (type == 2) {
      //  console.table(this.subans)
      //  console.log(op)
      let pr = this.subans.filter(p => p.isr == 1)
      for (let i = 0; i < pr.length; i++) {
        this.ans.push({ qsno: no, opno: i, sel: pr[i].sel, r: pr[i].r, w: pr[i].w, l: pr[i].l, t: pr[i].t })
      }
      this.subans = []
    }
    if (type == 3) {
      // if (this.subans.length == 0) {
      //   this.apiservice.getbycode('qsoptions', op, 'qsid')
      //     .subscribe(res => {
      //       this.opdata = res
      //       console.log(this.opdata)

      //       for (let i = 0; i < this.opdata.length; i++) {
      //         this.ans.push({ qsno: no + '-' + i, sel: 0, r: 0, w: 0, l: 0, t: 0 })
      //       }
      //       console.log(this.ans)
      //     })
      // } else {
      this.apiservice.getbyid('quizqs', op)
        .subscribe(res => {
          this.evaldata = res
          this.evalr = this.evaldata.reading / this.evaldata.anscount
          this.evalw = this.evaldata.writing / this.evaldata.anscount
          this.evall = this.evaldata.listening / this.evaldata.anscount
          this.evalt = this.evaldata.talking / this.evaldata.anscount
          this.subsel = 1 / this.evaldata.anscount
          for (let i = 0; i < this.subans.length; i++) {
            //  console.log(i)
            if (this.subans[i].sel == 1) {
              this.ans.push({ qsno: no, opno: i, sel: this.subsel, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
            } else {
              this.ans.push({ qsno: no, opno: i, sel: 0, r: 0, w: 0, l: 0, t: 0 })
            }
          }
          this.subans = []
        })

    }
    if (type == 4) {
      if (this.subans.length == 0) {
        this.apiservice.getbycode('qsoptions', op, 'qsid')
          .subscribe(res => {
            this.opdata = res
            //  console.log(this.opdata)

            for (let i = 0; i < this.opdata.length; i++) {
              this.ans.push({ qsno: no, opno: i, sel: 0 })
            }
            //  console.log(this.ans)
          })
      } else {
        for (let i = 0; i < this.subans.length; i++) {
          this.ans.push({ qsno: no, opno: i, sel: this.subans[i].sel, r: this.subans[i].r, w: this.subans[i].w, l: this.subans[i].l, t: this.subans[i].t })
        }
        this.subans = []
      }
    }
    if (type == 6) {
      this.apiservice.getbyid('quizqs', op)
        .subscribe(res => {
          this.evaldata = res
          this.evalr = this.evaldata.reading / this.evaldata.anscount
          this.evalw = this.evaldata.writing / this.evaldata.anscount
          this.evall = this.evaldata.listening / this.evaldata.anscount
          this.evalt = this.evaldata.talking / this.evaldata.anscount
          this.subsel = 1 / this.evaldata.anscount
          for (let i = 0; i < this.subans.length; i++) {
            // console.log(i)
            if (this.subans[i].sel == 1) {
              this.ans.push({ qsno: no, opno: i, sel: this.subsel, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
            } else {
              this.ans.push({ qsno: no, opno: i, sel: 0, r: 0, w: 0, l: 0, t: 0 })
            }
          }
          this.subans = []
        })
    }
    if (type == 7) {
      if (this.subans.length == 0) {
        this.apiservice.getbycode('qsoptions', op, 'qsid')
          .subscribe(res => {
            this.opdata = res
            //  console.log(this.opdata)

            for (let i = 0; i < this.opdata.length; i++) {
              this.ans.push({ qsno: no, opno: i, sel: 0, r: 0, w: 0, l: 0, t: 0 })
            }
            //  console.log(this.ans)
          })
      } else {
        for (let i = 0; i < this.subans.length; i++) {
          this.ans.push({ qsno: no, opno: i, sel: this.subans[i].sel, orid: this.subans[i].orid, r: this.subans[i].r, w: this.subans[i].w, l: this.subans[i].l, t: this.subans[i].t })
        }
        this.subans = []
      }
      this.sub = []
    }
    if (type == 8) {
      if (this.subans.length == 0) {
        this.apiservice.getbycode('qsoptions', op, 'qsid')
          .subscribe(res => {
            this.opdata = res
            // console.log(this.opdata)

            for (let i = 0; i < this.opdata.length; i++) {
              this.ans.push({ qsno: no, opno: i, sel: 0, r: 0, w: 0, l: 0, t: 0 })
            }
            //  console.log(this.ans)
          })
      } else {
        for (let i = 0; i < this.subans.length; i++) {
          this.ans.push({ qsno: no, opno: i, sel: this.subans[i].sel, orid: this.subans[i].orid, r: this.subans[i].r, w: this.subans[i].w, l: this.subans[i].l, t: this.subans[i].t })
        }
        this.subans = []
      }
    }

  }
  progmin() {
    this.width = this.width - (100 / (this.data.qs.length))
    // console.log("done")
  }
  res() {
    this.viewres = 1
  }

  ngAfterViewInit(): void {
    // Force another change detection in order to fix the ngFor error
    this._changeDetectionRef.detectChanges();
  }
  finishFunction(qzt) {
    console.log("2")
    this.uid = UUID.UUID();
    // console.table(this.ans)
    this.theDate = new Date();
    this.url = "https://beyanbook.com/portal/#/result/" + this.uid
    this.userid = localStorage.getItem('userid')
    this.scoure = this.ans.reduce((a: number, b) => a + b.sel, 0);
    let prrans = this.ans.filter(p => p.sel == 0)
    for (let k = 0; k < prrans.length; k++) {
      this.rans.push(prrans[k].qsno)
    }
    // console.log(this.rans)
    // console.log(this.scoure)
    this.testevalr = this.ans.reduce((a: number, b) => a + b.r, 0)
    this.testevalw = this.ans.reduce((a: number, b) => a + b.w, 0)
    this.testevall = this.ans.reduce((a: number, b) => a + b.l, 0)
    this.testevalt = this.ans.reduce((a: number, b) => a + b.t, 0)
    this.evaldataar.push({ name: "القراءة", eval: this.testevalr, userid: this.userid, testid: this.data.code, level: this.data.lcode })
    this.evaldataar.push({ name: "اﻹستماع", eval: this.testevall, userid: this.userid, testid: this.data.code, level: this.data.lcode })
    this.evaldataar.push({ name: "الكـتـابة", eval: this.testevalw, userid: this.userid, testid: this.data.code, level: this.data.lcode })
    this.evaldataar.push({ name: "تكلم", eval: this.testevalt, userid: this.userid, testid: this.data.code, level: this.data.lcode })
    if (this.scoure >= 15 && qzt == 1) {
      //  console.log(">15")
      this.userid = localStorage.getItem('userid')
      this.test.bcode = this.event.bcode
      this.test.qzorderno = this.event.orderno + 1
      this.apiservice.add('markcomp', { userid: this.userid, testid: this.event.code, scoure: this.scoure, qzt: qzt, uuid: this.uid })
        .subscribe(() => console.log("Done"))

      this.apiservice.add('ntest', this.test)
        .subscribe(res => {
          this.ntcode = res;
          this.ftest = {
            userid: this.userid,
            testid: this.ntcode[0].code,
            bcode: this.event.bcode,
            scoure: this.scoure
          };
          this.apiservice.add('addtest', this.ftest)
            .subscribe(res => {
              this.ntcode1 = res;
              //    console.log("addtest")
              this.apiservice.add('userevalad', this.evaldataar)
                .subscribe(res => {
                  this.done = res;
                  //   console.log(" add resuls")
                })
            })
          this.ftest = {
            userid: '',
            testid: '',
            bcode: '',
            scoure: 0
          };
        })
    }
    if (this.scoure >= 40 && qzt == 2) {
      //    console.log(">40")

      this.userid = localStorage.getItem('userid')
      this.test.bcode = this.event.bcode
      this.test.qzorderno = this.event.orderno + 1
      this.apiservice.add('markcomp', { userid: this.userid, testid: this.event.code, scoure: this.scoure, qzt: qzt, uuid: this.uid })
        .subscribe(() => console.log("Done"))
      this.apiservice.add('ntest', this.test)
        .subscribe(res => {
          this.ntcode = res;
          this.ftest = {
            userid: this.userid,
            testid: this.ntcode[0].code,
            bcode: this.event.bcode,
            scoure: this.scoure
          };
          this.apiservice.add('addtest', this.ftest)
            .subscribe(res => {
              this.ntcode1 = res;
              //  console.log("addtest")
              this.apiservice.add('userevalad', this.evaldataar)
                .subscribe(res => {
                  this.done = res;
                  //  console.log(" add resuls")
                })
            })
          this.ftest = {
            userid: '',
            testid: '',
            bcode: '',
            scoure: 0
          };
        })
    }
    if (this.scoure >= 70 && qzt == 3) {
      //  console.log(">70")
      this.userid = localStorage.getItem('userid')
      this.apiservice.add('markcomp', { userid: this.userid, testid: this.event.code, scoure: this.scoure, qzt: qzt, uuid: this.uid })
        .subscribe(() => console.log("Done"))
      this.apiservice.add('userevalad', this.evaldataar)
        .subscribe(res => {
          this.done = res;
          //   console.log(" add resuls")
        })
    }
    //  console.log(this.scoure,'last')


    // this.ans = []
  }
  async finishFunction1(qzt, no, type, op, val) {
    try {
      await this.progadd(no, type, op, val)
      await this.finishFunction(qzt)
    }
    catch (err) {
      return err
    }
  }
  finishFunctionbytime(qzt){
    console.log(qzt)
   
    
    if (qzt == 2){
      this.modalService.open(this.test2,this.modalOption)
      console.log('modal2')
      this.finishFunction(qzt)
    }else{
      this.modalService.open(this.test3,this.modalOption)
      console.log('modal3')
      this.finishFunction(qzt)
    }
   
    
  }
  reset() {
    this.viewdiv = 0
    this.ans = []
    this.subans = []
    // console.log(this.ans)
    //  console.log(this.subans)
    this.scoure = 0
    this.evaldataar = []
    this.testevalr = 0
    this.testevalw = 0
    this.testevall = 0
    this.testevalt = 0
    this.width = 0
    this.qstotal = this.data.qs.length
    this.width = 100 / (this.data.qs.length)
    this.wizard.navigation.reset()
    // console.log(this.ans)
    //  console.log(this.subans)
  }
  changestatuschk(r, t, i, f) {
    // console.log(r)
    // console.log(t)
    // console.log(i)
    // console.log(f)
    this.apiservice.getbyid('quizqs', t.qsid)
      .subscribe(res => {
        this.evaldata = res
        // console.log(this.evaldata)

        this.apiservice.getbycode('qsoptions', t.qsid, 'qsid')
          .subscribe(res => {
            this.opdata = res
            this.sub = this.opdata.filter(opdata => opdata.is_right == 1)
            //  console.log(this.sub.length, "len")
            this.evalr = this.evaldata.reading / this.sub.length
            this.evalw = this.evaldata.writing / this.sub.length
            this.evall = this.evaldata.listening / this.sub.length
            this.evalt = this.evaldata.talking / this.sub.length
            this.subsel = t.is_right / this.sub.length
            if (this.subans.length == 0) {
              for (let n = 0; n < this.opdata.length; n++) {
                this.subans.push({ id: this.opdata[n].id, sel: 0, orid: i, r: 0, w: 0, l: 0, t: 0, isr: this.opdata[n].is_right })
              }
              if (r.target.checked === false) {
                for (i = 0; i < this.subans.length; i++)
                  if (this.subans[i].id === t.id) {
                    this.subans.splice(i, 1);
                    // console.log("remove1")
                    break;
                  }
                // console.log(this.subans)
              } else {
                // console.log(this.subans)
                for (i = 0; i < this.subans.length; i++)
                  if (this.subans[i].id === t.id) {
                    this.subans.splice(i, 1);
                    //  console.log("remove2")
                    break;
                  }
                // console.log("add1")
                if (t.is_right == 1) {
                  this.subans.push({ id: t.id, sel: this.subsel, orid: i, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt, isr: t.is_right })
                  //  console.log("yes")
                } else {
                  this.subans.push({ id: t.id, sel: 0, orid: i, r: 0, w: 0, l: 0, t: 0, isr: t.is_right })
                  //  console.log("no")
                }

              }
            } else {
              if (r.target.checked === false) {
                for (i = 0; i < this.subans.length; i++)
                  if (this.subans[i].id === t.id) {
                    this.subans.splice(i, 1);
                    break;
                  }
                //   console.log(this.subans)
              } else {
                for (i = 0; i < this.subans.length; i++)
                  if (this.subans[i].id === t.id) {
                    this.subans.splice(i, 1);
                    break;
                  }
                //   console.log(this.subans)
                //   console.log("add2")
                if (t.is_right == 1) {
                  this.subans.push({ id: t.id, sel: this.subsel, orid: i, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt, isr: t.is_right })
                  //      console.log("yes")
                } else {
                  this.subans.push({ id: t.id, sel: 0, orid: i, r: 0, w: 0, l: 0, t: 0, isr: t.is_right })
                  //      console.log("no")
                }

              }
            }
          })
      })
    this.evalr = 0
    this.evalw = 0
    this.evall = 0
    this.evalt = 0
    this.sub = []
    this.subsel = 0

  }
  continue() {
    this.apiservice.getbyid('qz', '00U3L6QZ001')
      .subscribe(res => {
        this.qz = res;
        this.eventClicked.emit(this.qz)
      })
    this.subans = []
    this.ans = []
    this.scoure = 0
    this.evaldataar = []
    this.testevalr = 0
    this.testevalw = 0
    this.testevall = 0
    this.testevalt = 0
    this.width = 0
    this.qstotal = this.data.qs.length
    this.width = 100 / (this.data.qs.length)
  }
  changestatusinputtb(f, t) {
    if (f.target.value == t.answer) {
      // console.log(f.target.value)
      //  console.log(t.answer)
      for (var r = 0; r < this.subans.length; r++) {
        if (this.subans[r].id === t.id) {
          this.subans.splice(r, 1)
          break;
        }
      }
      this.subans.push({ id: t.id, sel: 1 })
      this.subans.sort((a, b) => {
        return a.id - b.id;
      })
    } else {
      for (var r = 0; r < this.subans.length; r++) {
        if (this.subans[r].id === t.id) {
          this.subans.splice(r, 1);
          break;
        }
      }
      this.subans.push({ id: t.id, sel: 0 })
      this.subans.sort((a, b) => {
        return a.id - b.id;
      })
    }
    // console.log(this.subans)
    // })
    // })
    this.sub = []
    // this.apiservice.getbyid('quizqs', t.qsid)
    //   .subscribe(res => {
    //     this.evaldata = res
    //     this.apiservice.getbycode('qsoptions', t.qsid, 'qsid')
    //       .subscribe(res => {
    //         this.opdata = res
    //         this.sub = this.opdata.filter(opdata => opdata.groupno == 2)
    //         console.log(this.sub)
    //         this.evalr = this.evaldata.reading / this.sub.length
    //         this.evalw = this.evaldata.writing / this.sub.length
    //         this.evall = this.evaldata.listening / this.sub.length
    //         this.evalt = this.evaldata.talking / this.sub.length
    //         this.subsel = 1 / this.sub.length
    //         if (this.subans.length == 0) {
    //           for (let n = 0; n < this.sub.length; n++) {
    //             this.subans.push({ id: this.sub[n].id, sel: 0, r: 0, w: 0, l: 0, t: 0 })
    //           }
    //           for (var r = 0; r < this.subans.length; r++) {
    //             if (this.subans[r].id === t.id) {
    //               this.subans.splice(r, 1);
    //               break;
    //             }
    //           }
    //           if (f.target.value == t.answer) {
    //             this.subans.push({ id: t.id, sel: this.subsel, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
    //             console.log(this.subans)
    //           } else {
    //             this.subans.push({ id: t.id, sel: 0, r: 0, w: 0, l: 0, t: 0 })
    //             console.log(this.subans)
    //           }
    //         } else {
    //           if (f.target.value == t.answer) {
    //             for (var r = 0; r < this.subans.length; r++) {
    //               if (this.subans[r].id === t.id) {
    //                 this.subans.splice(r, 1)
    //                 break;
    //               }
    //             }
    //             this.subans.push({ id: t.id, sel: this.subsel, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
    //             console.log(this.subans)
    //           } else {
    //             for (var r = 0; r < this.subans.length; r++) {
    //               if (this.subans[r].id === t.id) {
    //                 this.subans.splice(r, 1);
    //                 break;
    //               }
    //             }
    //             this.subans.push({ id: t.id, sel: 0, r: 0, w: 0, l: 0, t: 0 })
    //             console.log(this.subans)
    //           }
    //         }
    //       })
    //   })
    // this.evaldata = ""
    // this.evalr = 0
    // this.evalw = 0
    // this.evall = 0
    // this.evalt = 0
    // this.sub = []

  }
  changestatusinput(f, t, i) {
    if (f.target.value == t.answer) {
      // console.log(f.target.value)
      //   console.log(t.answer)
      for (var r = 0; r < this.subans.length; r++) {
        if (this.subans[r].id === t.id) {
          this.subans.splice(r, 1)
          break;
        }
      }
      this.subans.push({ id: t.id, sel: 1 })
      this.subans.sort((a, b) => {
        return a.id - b.id;
      })
    } else {
      for (var r = 0; r < this.subans.length; r++) {
        if (this.subans[r].id === t.id) {
          this.subans.splice(r, 1);
          break;
        }
      }
      this.subans.push({ id: t.id, sel: 0 })
      this.subans.sort((a, b) => {
        return a.id - b.id;
      })
    }
    // console.log(this.subans)
    // })
    // })
    this.sub = []
  }
  changesort(f, t, i) {
    this.apiservice.getbycode('qsoptions', i.qsid, 'qsid')
      .subscribe(res => {
        this.opdata = res
        //  console.log(f.target.value)
        //   console.log(t)
        //   console.log(i)
        this.apiservice.getbyid('quizqs', i.qsid)
          .subscribe(res => {
            this.evaldata = res
            // console.log(i)
            this.evalr = this.evaldata.reading / this.opdata.length
            this.evalw = this.evaldata.writing / this.opdata.length
            this.evall = this.evaldata.listening / this.opdata.length
            this.evalt = this.evaldata.talking / this.opdata.length
            this.subsel = 1 / this.opdata.length
            if (this.subans.length == 0) {
              for (let n = 0; n < this.opdata.length; n++) {
                this.subans.push({ id: this.opdata[n].id, sel: 0, r: 0, w: 0, l: 0, t: 0 })
              }
              for (var r = 0; r < this.subans.length; r++) {
                if (this.subans[r].id === i.id) {
                  this.subans.splice(r, 1);
                  break;
                }
              }
              if (f.target.value == t) {
                this.subans.push({ id: i.id, sel: this.subsel, orid: t, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
                //     console.log(this.subans)
              } else {
                this.subans.push({ id: i.id, sel: 0, orid: t, r: 0, w: 0, l: 0, t: 0 })
                //     console.log(this.subans)
              }
            } else {
              this.apiservice.getbycode('qsoptions', i.qsid, 'qsid')
                .subscribe(res => {
                  this.opdata = res
                  this.sub = this.opdata.length
                })
              if (f.target.value == t) {
                for (var r = 0; r < this.subans.length; r++)
                  if (this.subans[r].id === i.id) {
                    this.subans.splice(r, 1);
                    break;
                  }
                this.subans.push({ id: i.id, sel: this.subsel, orid: t, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
                console.log(this.subans)
              } else {
                for (var r = 0; r < this.subans.length; r++)
                  if (this.subans[r].id === i.id) {
                    this.subans.splice(r, 1);
                    break;
                  }
                this.subans.push({ id: i.id, sel: 0, orid: t, r: 0, w: 0, l: 0, t: 0 })
                //  console.log(this.subans)
              }
            }
          })
      })
    this.evaldata = ""
    this.evalr = 0
    this.evalw = 0
    this.evall = 0
    this.evalt = 0
    this.sub = []
  }
  oldval(r) {
    // console.log(r, "fc")
  }
  connect(f, t, i) {
    this.apiservice.getbycode('qsoptions', i.qsid, 'qsid')
      .subscribe(res => {
        this.opdata = res
        this.apiservice.getbyid('quizqs', i.qsid)
          .subscribe(res => {
            this.evaldata = res
            this.evalr = this.evaldata.reading / this.opdata.length
            this.evalw = this.evaldata.writing / this.opdata.length
            this.evall = this.evaldata.listening / this.opdata.length
            this.evalt = this.evaldata.talking / this.opdata.length
            this.subsel = 1 / this.opdata.length
            if (this.subans.length == 0) {
              for (let n = 0; n < this.opdata.length; n++) {
                this.subans.push({ id: this.opdata[n].id, sel: 0, r: 0, w: 0, l: 0, t: 0 })
              }
              for (var r = 0; r < this.subans.length; r++) {
                if (this.subans[r].id === i.id) {
                  this.subans.splice(r, 1);
                  break;
                }
              }
              if (f.target.value == t) {


                this.subans.push({ id: i.id, sel: this.subsel, orid: t, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
              //  console.log(this.subans)
              } else {
                this.subans.push({ id: i.id, sel: 0, orid: t, r: 0, w: 0, l: 0, t: 0 })
               // console.log(this.subans)
              }


            } else {
              if (f.target.value == t) {
                this.apiservice.getbycode('qsoptions', i.qsid, 'qsid')
                  .subscribe(res => {
                    this.opdata = res
                    this.sub = this.opdata.length
                  })
                for (var r = 0; r < this.subans.length; r++)
                  if (this.subans[r].id === i.id) {
                    this.subans.splice(r, 1);
                    break;
                  }


                this.subans.push({ id: i.id, sel: this.subsel, orid: t, r: this.evalr, w: this.evalw, l: this.evall, t: this.evalt })
               // console.log(this.subans)
              } else {
                for (var r = 0; r < this.subans.length; r++)
                  if (this.subans[r].id === i.id) {
                    this.subans.splice(r, 1);
                    break;
                  }
                this.subans.push({ id: i.id, sel: 0, orid: t, r: 0, w: 0, l: 0, t: 0 })
               // console.log(this.subans)
              }
            }
          })
      })
    this.evalr = 0
    this.evalw = 0
    this.evall = 0
    this.evalt = 0
    this.evaldata = ""
    this.sub = []
  }
  getans(r) {

    if (r == 1) {
      console.log("typ1")
    }
    if (r == 2) {
      console.log("typ2")
    }
    if (r == 3) {
      console.log("typ3")
    }
    if (r == 4) {
      console.log("typ4")
    }
    if (r == 5) {
      console.log("typ5")
    }
    if (r == 6) {
      console.log("typ6")
    }
    if (r == 7) {
      console.log("typ7")
    }
    if (r == 8) {
      console.log("typ8")
    }

  }
  cert(){
    this.link = "/cert/"+this.uid
    this.router.navigateByUrl(this.link)}
  onStart(r) {
    //console.log("starts",r)
  }
  onFinished(r) {
    //   console.log("stop",r)
  }
  onNotify(r) {
    this.qsp = 1
    //    console.log("note",r)
  }

  gettotal() {
    this.testtime = 0
    for (var i = 0, _len = this.data.qs.length; i < _len; i++) {
      this.testtime += this.data.qs[i].qstime
    }
    return this.testtime,
      console.log(this.testtime)
  }
  timerfn(num) {
    this.view = 0
    clearInterval(this.interval);
    this.counter = 0
    this.qsp = 0
    this.qstimedata = this.qstimedataall.filter(p => p.qsnum == num + 1)
    this.qstime = this.qstimedata[0].qstime
    // console.log(this.qstime,'thisone')

    //   displayTimeElapsed() {
    //     return Math.floor(this.seconds / 3600) + ':' + Math.floor(this.seconds / 60) + ':' + Math.floor(this.seconds % 60);
    // }
    this.counter = this.qstime;

    this.interval = setInterval(() => {
      // console.log(this.counter);
      this.counter--;
      this.displayTimeElapsed(this.counter)
      this.view = 1
      if (this.counter <= 0) {

        // The code here will run when
        //       // the timer has reached zero.

        clearInterval(this.interval);
        console.log('Ding!');
      };
      if (this.counter == this.qstime / 2) {
        this.qsp = 1
        //console.log('mid')
      }
    }, 1000);


  }
  displayTimeElapsed(r) {
    // this.cont =  Math.floor(r/ 60) + ':' + Math.floor(r % 60);
    this.cont = new Date(r * 1000).toISOString().substr(14, 5)
  }
}


