import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  @Input() alltest: Event;
  @Output() eventClicked = new EventEmitter<Event>();
  view = 0
  usersData: any;
  qz: any;
  id: any
  flevelbook: any;
  seclevelbook: any;
  thrlevelbook: any;
  forlevelbook: any;
  widthstep: number;
  utest: { userid: any; bcode: any; };
  userid: string;
  comstep: any;
  pvalue: number;
  nexttest: any;
  nexttestforuser: any;
  comlist: any;
  lev: any;
  levA1: any = 0
  levA2: any = 0
  levAE: any = 0
  levB1: any = 0
  levB2: any = 0
  levBE: any = 0
  levC1: any = 0
  levC2: any = 0
  levCE: any = 0
  lev0:  any = 0
  lev1:  any = 0
  lev2:  any = 0
  lev3:  any = 0

  constructor(private apiservice: ApiService) {
    this.userid = localStorage.getItem('userid')
  }
  rating: 4.3
  public isCollapsed: boolean


  uiStarClass(i, rating) {
    if (rating > (i - 1) && rating < i) { return 'half-filled'; }
    if (rating >= i) { return 'filled'; }
    return '';
  }


  ngOnInit() {
    this.view = 0
  
    this.apiservice.add('bookprose',{id:this.userid } )
      .subscribe(res => {
     
         this.comlist = res;
    for (let i = 0; i < this.comlist.length; i++) {
         
      if (this.comlist[i].bcode == "00") {
        this.lev0 = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "A1") {
        this.levA1 = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "A2") {
        this.levA2 = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "AE") {
        this.levAE = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "B1") {
        this.levB1 = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "B2") {
        this.levB2 = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "BE") {
        this.levBE = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "C1") {
        this.levC1 = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "C2") {
        this.levC2 = this.comlist[i].complet
      }
      if (this.comlist[i].bcode == "CE") {
        this.levCE = this.comlist[i].complet
      }
    }
    this.lev1 = (parseInt(this.levA1)  + parseInt(this.levA2) ) / 2
    this.lev2 = (parseInt(this.levB1) + parseInt(this.levB2) ) / 2
    this.lev3 = (parseInt(this.levC1) + parseInt(this.levC2) ) / 2
    this.usersData = this.alltest
    this.view = 1
})

    this.apiservice.getbycode('usertest', this.userid, 'userid')
      .subscribe(res => {
        this.nexttest = res
        let nextser = this.nexttest.length - 1
            this.nexttestforuser = this.nexttest[nextser].testid
        })

  }
  getqz(r) {
 
    this.apiservice.getbyid('qz', r.code)
      .subscribe(res => {
        this.qz = res;
        this.eventClicked.emit(this.qz)
      
      })
  }
  getqz1() {
    this.apiservice.getbyid('qz', this.flevelbook[0].code)
      .subscribe(res => {
        this.qz = res;
        this.eventClicked.emit(this.qz)
 
      })
  }
  getqz2() {
    this.apiservice.getbyid('qz', this.nexttestforuser)
      .subscribe(res => {
        this.qz = res;
        this.eventClicked.emit(this.qz)
    
      })
  }
 
}
