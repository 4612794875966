import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';



@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private JwtHelper: JwtHelperService) { }

    canActivate() {
        var token = localStorage.getItem('access_token');
        if ((localStorage.getItem('access_token')) && (this.JwtHelper.isTokenExpired(token) === false)) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
