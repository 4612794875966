import { Injectable } from '@angular/core';
import { Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { HttpClientModule } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ApiService {

    public apiUrl: string;
    public errorstyle: string = 'none';
    constructor(private http: HttpClient) { }

    
    public url() {
   // let url = "http://localhost:1337"
     let url = "https://app.beyanbook.net"
        return url
    }


getall(suburl: string) {
    
    return this.http.get(this.url() + '/' + suburl + '?limit=500')
            
}
get(suburl: string) {
    return this.http.get(this.url() + '/' + suburl )
            
}
add(suburl: string, info) {
  
    return this.http.post(this.url() + '/' + suburl, info)
       
}
getbycode(suburl: string, id, code) {
   
    return this.http.get(this.url() + '/' + suburl + '?' + code + '=' + id)
     
}
getbyid(suburl: string, id) {
   
    return this.http.get(this.url() + '/' + suburl + '/' + id)
        
}
updatemail(suburl: string, id, info) {
    return this.http.post(this.url() + '/' + suburl + '/' + id, info)
       
}
update(suburl: string, id, info) {
    return this.http.patch(this.url() + '/' + suburl + '/' + id, info)
       
}
delbyid(suburl: string, id) {
    return this.http.delete(this.url() + '/' + suburl + '/' + id)
}
/*addtrans(suburl: string, info) {
    let options = this.getHttpHeader();
    return this.http.post(this.url() + '/' + suburl, info)
        .map((response: Response) => response)
        .catch(this.handleError);

}
update(suburl: string, id, info) {
    let options = this.getHttpHeader();
    return this.http.put(this.url() + '/' + suburl + '/' + id, info)
        .map((response: Response) => response.json())
        .catch(this.handleError);;
}
    private handleError(error: any) {

    return Observable.throw(error.json());
}
getbyid(suburl: string, id) {
    let options = this.getHttpHeader();
    return this.http.get(this.url() + '/' + suburl + '/' + id)
        .map((response: Response) => response.json())
        .catch(this.handleError);
}
delbyid(suburl: string, id) {
    let options = this.getHttpHeader();
    return this.http.delete(this.url() + '/' + suburl + '/' + id)
        .map((response: Response) => response.json())
        .catch(this.handleError);
}
getassign(suburl: string, id) {
    let options = this.getHttpHeader();
    return this.http.get(this.url() + '/' + suburl + '?surid=' + id)
        .map((response: Response) => response.json())
        .catch(this.handleError);
}
getmysurvey(suburl: string, dep: string) {
    let options = this.getHttpHeader();
    return this.http.get(this.url() + '/' + suburl + '?department=' + dep + '&assigned=1')
        .map((response: Response) => response.json())
        .catch(this.handleError);
}
gettries(suburl: string, ids: string) {
    let options = this.getHttpHeader();
    return this.http.get(this.url() + '/' + suburl + '?ids=' + ids)
        .map((response: Response) => response.json())
        .catch(this.handleError);
}
*/

}

