import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './service/auth.guard';

// *******************************************************************************
// Layouts
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';

// *******************************************************************************
// Pages
import {MyevaluationComponent} from './sworkplace/myevaluation/myevaluation.component'
import {MygroupsComponent} from './sworkplace/mygroups/mygroups.component'
import {MyhomeComponent} from './sworkplace/myhome/myhome.component'
import {MylibComponent} from './sworkplace/mylib/mylib.component'
import {MyprofileComponent} from './sworkplace/myprofile/myprofile.component'
import {MytestComponent} from './sworkplace/mytest/mytest.component'
import { LoginComponent } from './sworkplace/authentication/login/login.component';
import { RegisterComponent } from './sworkplace/authentication/register/register.component';
import { PassResetComponent } from './sworkplace/authentication/pass-reset/pass-reset.component';
import { PassReset2Component } from './sworkplace/authentication/pass-reset2/pass-reset2.component';
import { ActiveAccountComponent } from './sworkplace/authentication/active-account/active-account.component';
import { PrivacyComponent } from './sworkplace/authentication/privacy/privacy.component';
import { ActivebookComponent } from './sworkplace/authentication/activebook/activebook.component';
import { MycertComponent } from './sworkplace/mycert/mycert.component';
import { EvalComponent } from './sworkplace/eval/eval.component';


// *******************************************************************************
// Routes

const routes: Routes = [

  {
    path: '', component: LayoutBlankComponent, pathMatch: 'full', children: [
      { path: '', component: MyhomeComponent,canActivate: [AuthGuard] },
         ]
  },
  { path: 'myeval', component: MyevaluationComponent },
  { path: 'mygroup', component: MygroupsComponent },
  { path: 'myhome', component: MyhomeComponent ,canActivate: [AuthGuard]},
  { path: 'mylib', component: MylibComponent },
  { path: 'myprofile', component: MyprofileComponent },
  { path: 'mytest', component: MytestComponent },
  { path: 'login/:id', component: ActivebookComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'passreset', component: PassResetComponent },
  { path: 'passreset2/:id', component: PassReset2Component },
  { path: 'activeaccount/:id', component: ActiveAccountComponent },
  { path: 'privacy', component: PrivacyComponent },
  {path: 'result/:id', component: EvalComponent },
  {path: 'cert/:id', component: MycertComponent }
  
];
// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes ,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
