import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../service/api.service';


@Component({
  selector: 'app-activebook',
  templateUrl: './activebook.component.html',
  styleUrls: ['./activebook.component.scss', '../../../../vendor/styles/pages/authentication.scss']
})
export class ActivebookComponent implements OnInit {
  public mid: string;
  alerts: Array<IAlert> = [];
  id: number;
  type: string;
  message: string;
  credentials = {
    login: '',
    password: '',
    code: ''
  };
  nothing: Object;
  constructor(private apiservice: ApiService,
    private router: ActivatedRoute,private route: Router) { }

  ngOnInit() {
    this.mid = this.router.snapshot.paramMap.get('id');
    this.credentials.code = this.mid
    console.log(this.mid)
  }
  onsubmit() {
    this.apiservice.add('activebook', this.credentials)
      .subscribe(
        data => {
          this.nothing = data
           console.log(1)
        },
        error => {
          if(error.status == 200 ){
            this.alerts.push({
              id: 1,
              type: 'info',
              message: 'لقد تم تفعيل الكتاب الخاص بكم '
            })
            this.credentials =  {
              login: '',
              password: '',
              code: ''
            }
            setTimeout(() => this.route.navigateByUrl(''), 4000)
           }
          if(error.status == 400 && error.error.err == "code already Used"){
          
            this.alerts.push({
              id: 1,
              type: 'danger',
              message: 'الكتاب مفعل مسبقا'
            })}
            if (error.status == 400 && error.error.err == "Code Not found"){
             
              this.alerts.push({
                id: 1,
                type: 'danger',
                message:  'رمز الكتاب خاطئ ' 
              })
          }
        },
        () => {
          console.log("Done")
        }

      )
  }


  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}
