import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../service/api.service';


@Component({
  selector: 'app-myevaluation',
  templateUrl: './myevaluation.component.html',
  styleUrls: ['./myevaluation.component.scss','../../../vendor/libs/spinkit/spinkit.scss'],
})
export class MyevaluationComponent implements OnInit {
  userid: any;
  allevaldata: any;
  singleData1: any;
  singleData: any;
  singleData2: any;
  singleData5:any;
  singleData4: any;
  singleData3: any;
  chart1 = 0
  chart2 = 0
  chart3 = 0
  chart4 = 0
  chart5 = 0
  loading: number;
  constructor(private apiservice: ApiService) {

  }
  colorScheme = {
    domain: ['#7c5255', '#7c527c', '#526e7c', '#5c527c']
  };




  uiStarClass(i, rating) {
    if (rating > (i - 1) && rating < i) { return 'half-filled'; }
    if (rating >= i) { return 'filled'; }
    return '';
  }
  ngOnInit() {
    this.loading = 1
    this.userid = localStorage.getItem('userid')
    this.apiservice.getbycode("usereval", this.userid, "userid")
      .subscribe(res => {
        this.allevaldata = res
        let p = this.allevaldata
        let pr = p.filter(p => p.name == "القراءة")
        let prval = pr.reduce((a: number, b) => a + b.value, 0)
        let pw = p.filter(p => p.name == "الكـتـابة")
        let pwval = pw.reduce((a: number, b) => a + b.value, 0)
        let pl = p.filter(p => p.name == "اﻹستماع")
        let plval = pl.reduce((a: number, b) => a + b.value, 0)
        let pt = p.filter(p => p.name == "تكلم")
        let ptval = pt.reduce((a: number, b) => a + b.value, 0)
       
        this.singleData = [{ name: 'القراءة', value: prval },
                           { name: 'اﻹستماع', value: plval },
                           { name: 'الكـتـابة', value: pwval },
                           { name: 'تكلم', value: ptval },
        ];


        let po = this.allevaldata.filter(allevaldata => allevaldata.level == "Level0")
        if (po.length > 0 )
        {this.chart1 = 1
        let pro = po.filter(po => po.name == "القراءة")
        let prvalo = pro.reduce((a: number, b) => a + b.value, 0)
        let pwo = po.filter(po => po.name == "الكـتـابة")
        let pwvalo = pwo.reduce((a: number, b) => a + b.value, 0)
        let plo = po.filter(po => po.name == "اﻹستماع")
        let plvalo = plo.reduce((a: number, b) => a + b.value, 0)
        let pto = po.filter(po => po.name == "تكلم")
        let ptvalo = pto.reduce((a: number, b) => a + b.value, 0)
        this.singleData1 = [{ name: 'القراءة', value: prvalo },
        { name: 'اﻹستماع', value: plvalo },
        { name: 'الكـتـابة', value: pwvalo },
        { name: 'تكلم', value: ptvalo },
        ];}

        let pa = this.allevaldata.filter(allevaldata => allevaldata.level == "LevelA")
        if (pa.length > 0 )
        {this.chart2 = 1
        let pra = pa.filter(pa => pa.name == "القراءة")
        let prvala = pra.reduce((a: number, b) => a + b.value, 0)
        let pwa = pa.filter(pa => pa.name == "الكـتـابة")
        let pwvala = pwa.reduce((a: number, b) => a + b.value, 0)
        let pla = pa.filter(pa => pa.name == "اﻹستماع")
        let plvala = pla.reduce((a: number, b) => a + b.value, 0)
        let pta = pa.filter(pa => pa.name == "تكلم")
        let ptvala = pta.reduce((a: number, b) => a + b.value, 0)
        this.singleData2 = [{ name: 'القراءة', value: prvala },
                           { name: 'اﻹستماع', value: plvala },
                           { name: 'الكـتـابة', value: pwvala },
                           { name: 'تكلم', value: ptvala },
        ];}

        let pb = this.allevaldata.filter(allevaldata => allevaldata.level == "LevelB")
        if (pb.length > 0 )
        {this.chart3 = 1
        let prb = pb.filter(pb => pb.name == "القراءة")
        let prvalb = prb.reduce((a: number, b) => a + b.value, 0)
        let pwb = pb.filter(pb => pb.name == "الكـتـابة")
        let pwvalb = pwb.reduce((a: number, b) => a + b.value, 0)
        let plb = pb.filter(pb => pb.name == "اﻹستماع")
        let plvalb = plb.reduce((a: number, b) => a + b.value, 0)
        let ptb = pb.filter(pb => pb.name == "تكلم")
        let ptvalb = ptb.reduce((a: number, b) => a + b.value, 0)
        this.singleData3 = [{ name: 'القراءة', value: prvalb },
        { name: 'اﻹستماع', value: plvalb },
        { name: 'الكـتـابة', value: pwvalb },
        { name: 'تكلم', value: ptvalb },
];}

        let pc = this.allevaldata.filter(allevaldata => allevaldata.level == "LevelC")
        if (pc.length > 0 )
        {this.chart4 = 1
        let prc = pc.filter(pc => pc.name == "القراءة")
        let prvalc = prc.reduce((a: number, b) => a + b.value, 0)
        let pwc = pc.filter(pc => pc.name == "الكـتـابة")
        let pwvalc = pwc.reduce((a: number, b) => a + b.value, 0)
        let plc = pc.filter(pc => pc.name == "اﻹستماع")
        let plvalc = plc.reduce((a: number, b) => a + b.value, 0)
        let ptc = pc.filter(pc => pc.name == "تكلم")
        let ptvalc = ptc.reduce((a: number, b) => a + b.value, 0)
        this.singleData4 = [{ name: 'القراءة', value: prvalc },
        { name: 'اﻹستماع', value: plvalc },
        { name: 'الكـتـابة', value: pwvalc },
        { name: 'تكلم', value: ptvalc },
];}

        let pd = this.allevaldata.filter(allevaldata => allevaldata.level == "LevelD")
        if (pd.length > 0 )
        {this.chart5 = 1
        let prd = pd.filter(pd => pd.name == "القراءة")
        let prvald = prd.reduce((a: number, b) => a + b.value, 0)
        let pwd = pd.filter(pd => pd.name == "الكـتـابة")
        let pwvald = pwd.reduce((a: number, b) => a + b.value, 0)
        let pld = pd.filter(pd => pd.name == "اﻹستماع")
        let plvald = pld.reduce((a: number, b) => a + b.value, 0)
        let ptd = pd.filter(pd => pd.name == "تكلم")
        let ptvald = ptd.reduce((a: number, b) => a + b.value, 0)
        this.singleData5 = [{ name: 'القراءة', value: prvald },
        { name: 'اﻹستماع', value: plvald },
        { name: 'الكـتـابة', value: pwvald },
        { name: 'تكلم', value: ptvald },
];}
           this.loading = 0
      }
     
      )
  }

}
