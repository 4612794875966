import { Injectable } from '@angular/core';
import {  Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable()
export class AuthService {
  public token: string;
  public url() {
   let url = "https://app.beyanbook.net"
   //let url = "http://localhost:1337"
    return url
}
  constructor(private http: HttpClient) {

  }

  login(subyurl, credentials) {
    return this.http.post(this.url() + '/' + subyurl, credentials)
     
  }
  logout(): void {
    this.token = null;
    localStorage.clear();
  }


}

