import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss']
})
export class MyprofileComponent implements OnInit {

  public form: FormGroup;
  myRawToken: string;
  userdata: any;
  mid: any
  pass1 = ""
  pass2 = ""
  mail: any;
  credentials = {
    email: '',
    pass: ''
  };
  alerts: Array<IAlert> = [];
  id: number;
  type: string;
  message: string;

  constructor(private apiservice: ApiService, private fb: FormBuilder, private helper: JwtHelperService) {

    if (localStorage.getItem('access_token')) {
      this.myRawToken = localStorage.getItem('access_token')
      const decodedToken = helper.decodeToken(this.myRawToken);
      this.mid = decodedToken.id
      this.mail = decodedToken.to
      //console.log(this.userdata)
    }
  }


  ngOnInit() {

    this.form = this.fb.group({
      fname: [null, Validators.compose([Validators.required])],
      lname: [null, Validators.compose([Validators.required])],
      mlang: [null, Validators.compose([Validators.required])],
      bdate: [null, Validators.compose([Validators.required])],
      contry: [null, Validators.compose([Validators.required])],
      study: [null, Validators.compose([Validators.required])],
      job: [null, Validators.compose([Validators.required])],
    })
    this.getdata()

  }
  onsubmit() {
    this.apiservice.update('email', this.mid, this.form.value)
      .subscribe(() => {
        console.log('Done')
        this.getdata()
      })
  }
  getdata() {
    this.apiservice.getbyid('email', this.mid)
      .subscribe(res => {
        this.userdata = res
        this.form.patchValue(this.userdata)
      })
  }
  changepass() {
    this.credentials = {
      email: this.mail,
      pass: this.pass1

    }
    this.apiservice.add('rpassword2', this.credentials)
      .subscribe(() => {
        this.alerts.push({
          id: 1,
          type: 'info',
          message: 'لقد تم تغيير كلمة المرور بنجاح'
        }),
          setTimeout(() => { this.alerts = []; }, 8000);
        this.credentials = {
          email: '',
          pass: ''
        }
      }
        , error => {
          if (error.status == 200) {
            this.alerts.push({
              id: 1,
              type: 'info',
              message: 'لقد تم تغيير كلمة المرور بنجاح'
            })
            this.credentials = {
              email: '',
              pass: ''
            }

          }


        })
  } public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}
