import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ApiService } from '../../service/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-mycert',
  templateUrl: './mycert.component.html',
  styleUrls: ['./mycert.component.scss']
})
export class MycertComponent implements OnInit {

  theDate: any;
  test = 0
  alltestdata: any;
  myRawToken: string;
  username: string;
  qzdata: Object;
  uid: any;
  constructor(private apiservice: ApiService, private helper: JwtHelperService,private route: ActivatedRoute) {
    if (localStorage.getItem('access_token')) {
      this.myRawToken = localStorage.getItem('access_token')
      const decodedToken = helper.decodeToken(this.myRawToken);
      this.username = decodedToken.fname + " " + decodedToken.lname
      console.log(this.username)
      this.uid = this.route.snapshot.params['id']
    }
  }

  ngOnInit() {
    this.apiservice.getbycode('usertest',this.uid ,'uuid')
      .subscribe(res => {
        this.alltestdata = res;
        console.log(this.alltestdata[0])
        this.theDate = this.alltestdata[0].updatedAt
        this.apiservice.getbycode('quizzes', this.alltestdata[0].testid, 'code')
          .subscribe(res => {
            this.qzdata = res;
            console.log(this.qzdata[0])
            this.test = this.qzdata[0].qztype
          })




      })
  }
  downloadPdfA() {
    var data = document.getElementById('modaA');
    console.log(data)
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('l', 'mm', 'a5'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF   
    });
  }
  downloadPdfB() {
    var data = document.getElementById('modaB');
    console.log(data)
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('l', 'mm', 'a5'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF   
    });
  }

  downloadPdfC() {
    var data = document.getElementById('modaC');
    console.log(data)
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('l', 'mm', 'a5'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF   
    });
  }


}
