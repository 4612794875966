import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SelectModule } from 'ng-select';
import { TagInputModule } from 'ngx-chips';
import { ChartsModule as Ng2ChartsModule } from 'ng2-charts/ng2-charts';
import { ArchwizardModule } from 'ng2-archwizard';
import { SortablejsModule } from 'angular-sortablejs';
import { NouisliderModule } from 'ng2-nouislider';
import { SidenavModule } from '../vendor/libs/sidenav/sidenav.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {NgPipesModule} from 'ngx-pipes';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// *******************************************************************************
// NgBootstrap

import { NgbModule  } from '@ng-bootstrap/ng-bootstrap';
// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './service/auth.guard';
import { ShareModule } from '@ngx-share/core';
import { CountdownModule } from 'ngx-countdown';

// *******************************************************************************
// Pages
import {MyevaluationComponent} from './sworkplace/myevaluation/myevaluation.component'
import {MygroupsComponent} from './sworkplace/mygroups/mygroups.component'
import {MyhomeComponent} from './sworkplace/myhome/myhome.component'
import {MylibComponent} from './sworkplace/mylib/mylib.component'
import {MyprofileComponent} from './sworkplace/myprofile/myprofile.component'
import {MytestComponent} from './sworkplace/mytest/mytest.component'
import {GeneralComponent} from './sworkplace/general/general.component'
import { LoginComponent } from './sworkplace/authentication/login/login.component';
import { RegisterComponent } from './sworkplace/authentication/register/register.component';
import { PassResetComponent } from './sworkplace/authentication/pass-reset/pass-reset.component';
import { PassReset2Component } from './sworkplace/authentication/pass-reset2/pass-reset2.component';
import { ActiveAccountComponent } from './sworkplace/authentication/active-account/active-account.component';
import { PrivacyComponent } from './sworkplace/authentication/privacy/privacy.component';
import { ActivebookComponent } from './sworkplace/authentication/activebook/activebook.component';
import { SafePipe } from './interface/safe.pipe';






// *******************************************************************************
//service 
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ApiService } from './service/api.service';
import { AuthService } from './service/auth.service';

import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MycertComponent } from './sworkplace/mycert/mycert.component';
import { EvalComponent } from './sworkplace/eval/eval.component';
import { MySoundsComponent } from './sworkplace/my-sounds/my-sounds.component';
import { MyPromoComponent } from './sworkplace/my-promo/my-promo.component';
import { AudioPlayerComponent } from './sworkplace/audio-player/audio-player.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    // Pages
    MyevaluationComponent,
    MygroupsComponent,
    PassReset2Component,
    ActiveAccountComponent,
    MyhomeComponent,
    PassResetComponent,
    MylibComponent,
    RegisterComponent,
    LoginComponent,
    MyprofileComponent,
    MytestComponent,
    GeneralComponent,
    PrivacyComponent,
    ActivebookComponent,
    MycertComponent,
    EvalComponent,
    MySoundsComponent,
    MyPromoComponent,
    AudioPlayerComponent,
    SafePipe
  ],

  imports: [
    
    BrowserModule,
    SelectModule,
    TagInputModule,
    Ng2ChartsModule,
    ArchwizardModule,
    SortablejsModule,
    NouisliderModule,
  
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    SidenavModule,
    CountdownModule,
    NgxChartsModule,
    NgPipesModule,
    NgbModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ShareModule.forRoot(),
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-lg btn-primary',
      cancelButtonClass: 'btn btn-lg btn-default'
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['beyanbook.net:8000'],
         blacklistedRoutes: ['beyanbook.net:8000/login'],
      //  whitelistedDomains: ['localhost:1337'],
      //  blacklistedRoutes: ['localhost:1337/login'],
        skipWhenExpired: true,
      }}),
      
  ],

  providers: [
    Title,
    AppService,
   
    ApiService,AuthService,AuthGuard],
    

  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
