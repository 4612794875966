import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-pass-reset2',
  templateUrl: './pass-reset2.component.html',
  styleUrls: ['./pass-reset2.component.scss', '../../../../vendor/styles/pages/authentication.scss']
})
export class PassReset2Component implements OnInit {



  constructor(private router: ActivatedRoute,private route: Router, private apiservice: ApiService) { }
  alerts: Array<IAlert> = [];
  id: number;
  type: string;
  message: string;
  credentials = {
    email: '',
    pass: ''
  };
  mid: any

  ngOnInit() {
    this.mid = this.router.snapshot.paramMap.get('id');
    this.credentials.email = this.mid
  }

  onsubmit() {
    this.apiservice.add('rpassword2', this.credentials)
      .subscribe(() => {
        this.alerts.push({
          id: 1,
          type: 'info',
          message: 'لقد تم تغيير كلمة المرور بنجاح'
        }),
          setTimeout(() => { this.alerts = []; }, 8000);
        this.credentials = {
          email: '',
          pass: ''
        }
      }
        , error => {
          if(error.status == 200 ){
            this.alerts.push({
              id: 1,
              type: 'info',
              message:'لقد تم تغيير كلمة المرور بنجاح'
            })
            this.credentials =  {
              email: '',
              pass: ''       
            }
            setTimeout(() => this.route.navigateByUrl(''), 4000)
           }
         

        })


  }
  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}

