import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../../../vendor/styles/pages/authentication.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private apiservice: ApiService,) { }
  credentials = {
    name:'',
    email: '',
    password: '',
   
  };
  ngOnInit() {
  }
  alerts: Array<IAlert> = [];
  id: number;
  type: string;
  message: string;
  onSubmit() {
    this.apiservice.add('email', this.credentials)
        .subscribe(() => {
          this.alerts.push({
            id: 1,
            type: 'info',
            message: 'شكرا لتسجيلكم لقد تم ارسال رسالة الى البريد الخاص بكم يرجى فتح الرسالة واستخدام الرابط لتفعيل الحساب الخاص بكم '
          }),
            setTimeout(() => { this.alerts = []; }, 8000);
        this.credentials ={
          name:'',
          email: '',
          password: '',
        }
        }
          , error => {
            console.log(error)
            this.alerts.push({
              id: 1,
              type: 'danger',
              message: 'يرجى التأكد من اسم المستخدم وكلمة المرور'
            }),
              setTimeout(() => { this.alerts = []; }, 3000);
          })
    }
    public closeAlert(alert: IAlert) {
      const index: number = this.alerts.indexOf(alert);
      this.alerts.splice(index, 1);
    }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}
