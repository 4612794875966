import { Component, OnInit,Output, EventEmitter, Input, ViewChild, ElementRef, Renderer2  } from '@angular/core';
import { ApiService } from '../../service/api.service';


@Component({
  selector: 'app-my-promo',
  templateUrl: './my-promo.component.html',
  styleUrls: ['./my-promo.component.scss']
})
export class MyPromoComponent implements OnInit {
  @Input() alltest: Event;
  @Output() eventClicked = new EventEmitter<Event>();
  view = 0
  usersData: any;
  qz: any;
  id: any
  flevelbook: any;
  seclevelbook: any;
  thrlevelbook: any;
  forlevelbook: any;
  widthstep: number;
  utest: { userid: any; bcode: any; };
  userid: string;
  comstep: any;
  pvalue: number;
  nexttest: any;
  nexttestforuser: any;
  comlist: any;
  lev: any;
  levA1: any = 0
  levA2: any = 0
  levAE: any = 0
  levB1: any = 0
  levB2: any = 0
  levBE: any = 0
  levC1: any = 0
  levC2: any = 0
  levCE: any = 0
  lev0:  any = 0
  lev1:  any = 0
  lev2:  any = 0
  lev3:  any = 0
  yt = '<iframe class="w-100 embed-responsive-item"  height="800px" src="https://beyanbook.net/pdf/1/index.html" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
  
  constructor(private apiservice: ApiService,private renderer: Renderer2) {
    this.userid = localStorage.getItem('userid')
  }
  rating: 4.3
  public isCollapsed: boolean
  editable = true;

  @ViewChild('myDiv') myDiv: ElementRef;




  ngOnInit() {
 
    this.view = 0

   
    this.usersData = this.alltest
    this.view = 1
    console.log(this.alltest)


  }
  getqz(r) {
   // if(r.userid)
    this.apiservice.getbyid('qz', r.code)
      .subscribe(res => {
        this.qz = res;
        this.eventClicked.emit(this.qz)
         console.log(this.qz)
      })
  }
  getqz1() {
    this.apiservice.getbyid('qz', this.flevelbook[0].code)
      .subscribe(res => {
        this.qz = res;
        this.eventClicked.emit(this.qz)
       // console.log(this.qz, "1")
      })
  }
  getqz2() {
    this.apiservice.getbyid('qz', this.nexttestforuser)
      .subscribe(res => {
        this.qz = res;
        this.eventClicked.emit(this.qz)
       // console.log(this.qz, "%c 2 ,color:red;")
      })
  }
 

}
