import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-my-sounds',
  templateUrl: './my-sounds.component.html',
  styleUrls: ['./my-sounds.component.scss']
})
export class MySoundsComponent implements OnInit {

  @Input() alltest: Event;
  @Input() allsounds: any;

  view = 0
  usersData: any;
  qz: any;
  id: any


  userid: string;

  lev0Data: any;
  lev1Data: any;
  lev2Data: any;
  lev3Data: any;
  truckdata: any;
  sounds: any;


  constructor() {
    this.userid = localStorage.getItem('userid')
  }

  public isCollapsed: boolean




  ngOnInit() {
    this.usersData = this.alltest
    this.sounds = this.allsounds
    this.lev0Data = this.allsounds.filter(p => p.lcode == 'Level0')
    this.lev1Data = this.allsounds.filter(p => p.lcode == 'LevelA')
    this.lev2Data = this.allsounds.filter(p => p.lcode == 'LevelB')
    this.lev3Data = this.allsounds.filter(p => p.lcode == 'LevelC')
  }



  selectTruck(r) {

    this.truckdata = r
  }


}
