import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../../../vendor/styles/pages/authentication.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private authservice: AuthService,
    private router: Router) { }

  alerts: Array<IAlert> = [];
  id: number;
  type: string;
  message: string;

  userData: any
  token: string
  error: any
  restoken: any
  credentials = {
    login: '',
    password: '',

  };

  ngOnInit() {
    this.logout();
  }
  onSubmit() {
  this.authservice.login('email/login', this.credentials)
      .subscribe(res => {
        this.restoken = res;
        localStorage.setItem('access_token', this.restoken.token);
        this.router.navigateByUrl('');
      }
        , error => {
          console.log(error)
          this.alerts.push({
            id: 1,
            type: 'danger',
            message: ' يرجى التأكد من اسم المستخدم وكلمة المرور او ان الحساب غير مفعل يرجى تفيل الحساب عن طريق الالرسلة المرسلة لكم عبر البريد اﻹلكتروني'
          }),
            setTimeout(() => { this.alerts = []; }, 5000);
        })
  }
  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
  logout(){
    localStorage.removeItem('access_token')
  }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}


