import { Component, OnInit, Input } from '@angular/core';
import { AudioPlayerTrack } from './audioplayer-track';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})

export class AudioPlayerComponent implements OnInit {

  @Input() levelsound: any
  @Input() truckdata: any;

  pros: number = 0;
  play = 0
  vol: number = 1;
  dur: number = 1;
  constructor() {

  }
  private __audio: any = null;
  private __playing = false;
  private __tracks: any[];
  private __currentTrack: AudioPlayerTrack;
  private __trackIndex: any;
  panelOpenState = false;

  __playTrack() {
    this.play = 1
    this.__audio.play();
    this.__audio.ondurationchange = function () { }
    this.__audio.addEventListener("timeupdate", (currentTime) => { });
    this.__playing = true;
  }
  __pauseTrack() {
    this.play = 0
    this.__audio.pause();
    this.__playing = false;
  }
  __stopTrack() {
    this.play = 0
    this.__audio.pause();
    this.__playing = false;
    this.__audio.currentTime = 0;
  }
  __nextTrack() {
    this.play = 1
    this.__stopTrack();
    this.__trackIndex++;
    if (this.__trackIndex > this.__tracks.length) { this.__trackIndex = 0; }
    this.__currentTrack = this.__tracks[this.__trackIndex];
    this.__audio.src = 'https://beyanbook.net/beyan-admin/assets/sounds/' + this.__currentTrack.path;
    this.__audio.ondurationchange = function () { }
    this.__audio.addEventListener("timeupdate", (currentTime) => { });
    this.__audio.load();
    this.__playTrack();
  }
  __previousTrack() {
    this.play = 1
    this.__stopTrack();
    this.__trackIndex--;
    if (this.__trackIndex < 0) {
      this.__trackIndex = this.__tracks.length - 1;
    }
    this.__currentTrack = this.__tracks[this.__trackIndex];
    this.__audio.src = 'https://beyanbook.net/beyan-admin/assets/sounds/' + this.__currentTrack.path;
    this.__audio.ondurationchange = function () { }
    this.__audio.addEventListener("timeupdate", (currentTime) => { });
    this.__audio.load();
    this.__playTrack();
  }
  __setTrack(track: AudioPlayerTrack) {
    this.play = 1
    this.__stopTrack();
    console.log(track.id)
    this.__trackIndex = this.__tracks.findIndex(x => x.id == track.id); //   a.findIndex(x => x.prop2 ==="yutu");
    console.log(this.__trackIndex)
    this.__currentTrack = this.__tracks[this.__trackIndex];
    this.__audio.src = 'https://beyanbook.net/beyan-admin/assets/sounds/' + this.__currentTrack.path;
    this.__audio.ondurationchange = function () { }
    this.__audio.load();
    this.__audio.addEventListener("timeupdate", (currentTime) => { });
    this.__playTrack();

  }
  __setVolume(vol) {
    console.log(vol)
    this.vol = vol
    this.__audio.volume = vol;
  }
  __setProgress(prog) {
    console.log(prog);
    this.__audio.currentTime = prog;

  }
  ngOnInit() {
    this.__audio = new Audio();
    this.__tracks = this.levelsound
    console.log(this.__tracks)
    this.__trackIndex = 0;  // pieces.indexOf('rook')
    console.log(this.__trackIndex)
    this.__currentTrack = this.__tracks[this.__trackIndex];
    console.log(this.__currentTrack)
  }
  ngOnDestroy() {
    if (this.__audio) {
      this.__audio.pause();
      this.__audio = null;
    }

  }

  ngOnChanges() {
    console.log('nochange')
    if (this.truckdata) {
      
      console.log('change')
      
      this.__audio = new Audio();
      
      this.__setTrack(this.truckdata)
    }

  }
}
