import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { ShareButtons } from '@ngx-share/core';


@Component({
  selector: 'app-eval',
  templateUrl: './eval.component.html',
  styleUrls: ['./eval.component.scss']
})
export class EvalComponent implements OnInit {


  sub: any
  uid: any;
  data: any;
  result: any;
  qzt: number;
  scoure: number;
  buttonName = "facebook"
  url: any;
  constructor(private route: ActivatedRoute, private apiservice: ApiService,public share: ShareButtons) {
    this.uid = this.route.snapshot.params['id']
   this.url = "https://beyanbook.com/portal/#/result/"+this.uid
  }

  ngOnInit() {
    this.getdata()
  }
  getdata() {
    this.apiservice.getbycode('usertest', this.uid, 'uuid')
      .subscribe(res => {
        this.result = res;
        console.log(this.result)
        this.data = this.result[0]
        this.qzt = this.data.qzt
        this.scoure = this.data.scoure
      })
  }
}




