import { Component, OnInit , AfterViewInit} from '@angular/core';
import { ApiService } from '../../service/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Component({
  selector: 'app-myhome',
  templateUrl: './myhome.component.html',
  styleUrls: ['./myhome.component.scss']
})
export class MyhomeComponent implements OnInit  {
  alltestdata: any;
  myRawToken: any;
  books : any;
  isExpanded1 = false
  bcode: string;
  allsounds: any;

  constructor(private apiservice: ApiService,private helper: JwtHelperService,private router: Router) {
   
     if (localStorage.getItem('access_token')) {
       this.myRawToken = localStorage.getItem('access_token')
      const decodedToken = helper.decodeToken(this.myRawToken);
       this.id = decodedToken.id
       localStorage.setItem('userid', decodedToken.id);        
     }
    
   }
  id : any
  curTab = 'lib';
  public isCollapsed = true;

  public clickedEvent: Event;

  childEventClicked(event: Event) {
    this.clickedEvent = event;
   // console.log(this.clickedEvent)
    this.curTab = 'test'
    
}
 childEventClicked1(event: Event) {
  this.clickedEvent = event;
 // console.log(this.clickedEvent)
  this.curTab = 'general'
}

  ngOnInit() {
    this.apiservice.get('lv/'+this.id)
    .subscribe(res => {
      this.alltestdata = res;
      // console.log(this.alltestdata,"see here")
     
  })
  this.apiservice.getall('sounds')
  .subscribe(res => {
    this.allsounds = res
      
  })


}
  logout(){
  this.router.navigateByUrl('login');
 }
}
