import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';


@Component({
  selector: 'app-mylib',
  templateUrl: './mylib.component.html',
  styleUrls: ['./mylib.component.scss'
  ]
})
export class MylibComponent implements OnInit {
  usersData: any
  myRawToken: any;
  id: any;
  cup = 0
  userstest: any;

  lev0: any;
  lev1: any;
  lev2: any;
  lev3: any;
  allquizzes: any;
  alev0: any;
  alev1: any;
  alev2: any;
  alev3: any;
  alev0widthstep: number;
  lev0comtest: any;
  alev1widthstep: number;
  lev1comtest: number;
  usertestcom: any;
  comlist: any
  lev: any;
  levA1: any = 0
  levA2: any = 0
  levAE: any = 0
  levB1: any = 0
  levB2: any = 0
  levBE: any = 0
  levC1: any = 0
  levC2: any = 0
  levCE: any = 0

  constructor(private apiservice: ApiService, private helper: JwtHelperService) {
    if (localStorage.getItem('access_token')) {
      this.myRawToken = localStorage.getItem('access_token')
      const decodedToken = helper.decodeToken(this.myRawToken);
      this.id = decodedToken.id
    }
  }


  ngOnInit() {
 
    this.apiservice.getbyid('userbook', this.id)
      .subscribe(res => {
        this.usersData = res;
       
      })

    this.apiservice.add('bookprose',{id:this.id} )
      .subscribe(res => {
        // console.log('bey')
         this.comlist = res;
        // console.log(this.comlist,'thisdata')
          for (let i = 0; i < this.comlist.length; i++) {
         
          if (this.comlist[i].bcode == "00") {
            this.lev0 = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "A1") {
            this.levA1 = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "A2") {
            this.levA2 = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "AE") {
            this.levAE = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "B1") {
            this.levB1 = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "B2") {
            this.levB2 = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "BE") {
            this.levBE = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "C1") {
            this.levC1 = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "C2") {
            this.levC2 = this.comlist[i].complet
          }
          if (this.comlist[i].bcode == "CE") {
            this.levCE = this.comlist[i].complet
          }

        }
        
      }
    )
  }


}
