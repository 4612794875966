import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-active-account',
  templateUrl: './active-account.component.html',
  styleUrls: ['./active-account.component.scss', '../../../../vendor/styles/pages/authentication.scss']
})
export class ActiveAccountComponent implements OnInit {
 div = 1
  public mid: string;
  alerts: Array<IAlert> = [];
  id: number;
  type: string;
  message: string;
  constructor(private route: ActivatedRoute, private apiservice: ApiService) {
    //   this.activatedRoute.queryParams.subscribe(params => {
    //     let date = params['startdate'];
    //     console.log(date); // Print the parameter to the console. 
    // });
  }

  ngOnInit() {
    this.mid = this.route.snapshot.paramMap.get('id');
    console.log(this.mid)

    this.apiservice.updatemail('activemail', this.mid, this.mid)
      .subscribe(() => {
        this.div =1
        this.alerts.push({
          id: 1,
          type: 'info',
          message: 'لقد تم تفعيل الحساب الخاص بكم يمكنم اﻷن تسجيل الدخول '
        })},
        error => {
          if (error.status == 400 && error.error.err == "Account already Active") {
            this.div = 0
            this.alerts.push({
              id: 1,
              type: 'danger',
              message: 'الحساب مفعل مسبقا'
            })
             
          } if (error.status == 400 && error.error.err == "Code Not found"){
            this.div = 0
            this.alerts.push({
              id: 1,
              type: 'danger',
              message:  'الكود المدخل خاطئ ' 
            })
          }})
  }
  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}



